

    .post-card-container {
        width: 80%;
        margin: 10px auto;
        padding: 20px;
        
        overflow: auto;
        .post-container {
            max-height: calc(100vh - 60px);
            overflow: auto;
        }
        .post-back {
            width: 100%;
        }
        .post-title {
            font-size: 2.5rem;
            font-weight: 400;
            width: 100%;
            text-align: center;
        }
        .post-author {
            width: 100%;
            text-align: right;
            color: #666;
        }
        .post-description {
            width: 80%;
            min-height: 150px;
            padding: 20px 15px;
            color: #000;
            margin: 5px auto;
            border: solid thin #999;
            border-radius: 20px;
            background: #eee;
        }
        .post-list-vote {
            .agree {
                background-color: #333;
                color: green;
                margin: 5px;
            }
        }
        
    }
    .comment-section {
        width: 100%;
        position: relative;
    }
    .comment-container {
        width: 90%;
        margin: 2px 0 2px auto;
        padding: 10px 5px;
        border-left: solid thin #333;
    }

    .image-post-container {
        max-width: 700px;
        margin: 10px auto;
        img {
            width: 100%;
        }
    }

.sm, .md {
    .post-card-container {
        width: 100%;
        margin: 0;
        padding: 0 20px;
        border-radius: 0;
        font-size: 0.9rem;
        .post-container {
            max-height: 100vh;
            overflow: auto;
        }
        .post-title {
            font-size: 30px;
            padding: 0 20px;
        }
        .post-description {
            border-radius: 3px;
            width: 100%;
        }
    }
}
.academy-list-container {
    width: 80%;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    height: auto;
    overflow: auto;
    .academy-list {
        width: 100%;
        border: solid thin #333;
        border-radius: 5px;
        margin: 0;
        padding: 5px 20px;
        background-color: #fff;
        cursor: pointer;
        transition: ease-in linear 1s;
        &.highlighted {
            background: lightblue;
        }
    }
}
.schedule-box {
    width: calc(100%/8);
    height: auto;
    padding: 10px;
    word-wrap: break-word;
    overflow: auto;
    border: solid thin #333;
    text-align: center;
}
.schedule-container {
    max-width: calc(100vw - 10px);
    overflow: scroll;
}
// .details-container {
//     position: absolute;
//     background: #fff;
//     display: block
// }
@use './profile.scss';
@use './posts.scss';
@use './layout.scss';
@use './academy.scss';

.box-container {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    align-content: flex-start;
    height: 100vh;
    max-height: 100vh;
    &.gap-1 {
        gap: 10px;
    }
    &.gap-2 {
        gap: 20px;
    }
    &.gap-3 {
        gap: 30px;
    }
    &.gap-4 {
        gap: 40px;
    }
    .box-item-1, .box-item-2, .box-item-3, .box-item-4 {
        width: 10%;
        height: 100%;
    }
    .box-item-1 {
        flex-grow: 1;
    }
    .box-item-2 {
        flex-grow: 2;
    }
    .box-item-3 {
        flex-grow: 3;
    }
    .box-item-4 {
        flex-grow: 4;
    }
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    min-width: 400px;
    min-height: 200px;
    background-color: #fff;
    border: 2px solid #000;
    box-shadow: 24;
    padding: 4;
}
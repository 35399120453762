.image_container {
    width: 300px;
    height: 300px;
    overflow: hidden;
    text-align: center;
    border: solid 3px #333;
    border-radius: 50%;
    position: relative;
    img {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.profile-list-container {
    padding: 10px;
    overflow: auto;
}
$top_bar_link_button: #fff;



a {
    color: #3498db;
    text-decoration: none;
    &:hover {
        color: #2980b9;
    }
    &:visited, &:active {
        color: #3498db;
    }
}
.home-logo {
    height: 40px;
}
.main-content {
    background-color: #f9f9f9;
    max-height: 100vh;
    padding-top: 100px;
    overflow: auto;
}
.top-bar-nav-links {
    color: #fff;
    text-decoration: none;
    &:hover, &:visited, &:active {
        color: #fff;
        text-decoration: none;
    }
}
.sm, .md {
    .main-content {
        max-height: calc(100vh - 60px);
    }
}
.lg {
    .left-drawer {
        padding-top: 100px;
        background-color: #ecf0f1;
        width: 10%;
        border-right: solid #bdc3c7 thin;
    }
    .details-container {
        .academy_find_details {
            width: 80%;
            margin: 10px auto;
            padding: 20px;
            border: solid thin #333;
            border-radius: 10px;
            box-shadow: 0px 2px 2px #aaa;
        }
    }
    .auth_container {
        width: 50%;
        margin: 20px auto;
        .button-group {
            margin: 20px 0;
        }
        .login-disclaimer {
            text-align: center;
            color: #aaa;
        }
    }
}